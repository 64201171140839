<template>
  <div>
    <h1 class="title">Contact Groups</h1>
    <div class="content">
      <p>
        A contact group a collection of contact details (such as name, email, telephone and role),
        Which can then be assigned to a destination or a product. This will then appear related to
        orders to make it easier to contact the subs house or publisher
      </p>
    </div>
    <div class="columns">
      <div class="column is-one-quarter">
        <div class="field">
          <label class="label">Name (Contains)</label>
          <div class="control">
            <div style="white-space:nowrap">
              <input
                class="input"
                type="text"
                placeholder="name"
                v-model="search.nameContains"
                v-on:change="updateFilter"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Type</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="search.groupType" v-on:change="updateFilter">
                <option :value="null">All</option>
                <option value="Destination">Fulfilment Bureau</option>
                <option value="Product">Customer Service</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Page size</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="search.size" v-on:change="updateFilter">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns" v-if="ContactGroups">
      <div class="column is-four-fifths" style="display:flex">
        <b-pagination
          size="is-small"
          :simple="false"
          :total="ContactGroups.TotalRows"
          :current.sync="search.currentPaginationPage"
          :per-page="ContactGroups.Limit"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          v-on:change="paginatePage"
        ></b-pagination>
      </div>
      <div class="column has-text-right">
        <button class="button is-primary is-medium" v-on:click="addContactGroup">Add Contact Group</button>
      </div>
      <br />
    </div>

    <div v-if="isLoading">
      <a class="button is-large is-loading">Loading</a>
      <br />
      <br />
    </div>
    <table
      class="table is-size-6 is-fullwidth"
      v-else-if="ContactGroups !== null && ContactGroups.Results.length > 0"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Contacts #</th>
          <th>Type</th>
          <th>Linked Items</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="d in ContactGroups.Results" :key="d.Id">
          <td>{{ d.Id }}</td>
          <td>
            <b>
              <a v-on:click="EditContactGroup = d">{{ d.Name }}</a>
            </b>
          </td>
          <td>
            <span v-if="d.Contacts.length === 1">
              <table class="table-plain">
                <tr>
                  <td v-if="d.Contacts[0].Name && d.Contacts[0].Name.length > 0">
                    <strong>Name:</strong>
                    {{d.Contacts[0].Name }}
                  </td>
                  <td v-if="d.Contacts[0].Role && d.Contacts[0].Role.length > 0">
                    <strong>Role:</strong>
                    {{d.Contacts[0].Role }}
                  </td>
                </tr>
                <tr>
                  <td v-if="d.Contacts[0].Email && d.Contacts[0].Email.length > 0">
                    <strong>Email:</strong>
                    {{d.Contacts[0].Email }}
                  </td>
                  <td v-if="d.Contacts[0].Telephone && d.Contacts[0].Telephone.length > 0">
                    <strong>Tel:</strong>
                    {{d.Contacts[0].Telephone }}
                  </td>
                </tr>
              </table>
            </span>
            <span v-else-if="d.Contacts.length === 0">No Contacts</span>
            <span v-else>{{ d.Contacts.length }} Contacts</span>
          </td>
          <td>{{d.GroupType === 1 ? "Customer Service" : "Fulfilment Bureau" }}</td>
          <td>
            <router-link
              v-if="d.GroupType === 1 && d.LinkedProducts > 0"
              title="Products linked to this contact"
              :to="'/product-config?contactGroupId=' + d.Id"
            >{{ d.LinkedProducts }} Products</router-link>
            <span
              v-if="d.GroupType === 0 && d.LinkedDestinations > 0"
            >{{ d.LinkedDestinations }} Destinations</span>
            <span v-if="(d.LinkedDestinations +d.LinkedProducts) === 0">Nothing</span>
          </td>
          <td>
            <div class="buttons" style="justify-content: flex-end">
              <button class="button is-small" v-on:click="EditContactGroup = d">Edit / View</button>
              <button
                class="button is-small is-danger"
                v-on:click="confirmDeleteContactGroup(d)"
              >Delete</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      No Results found
      <br />
      <br />
    </div>

    <div class="columns" v-if="ContactGroups">
      <div class="column is-four-fifths" style="display:flex">
        <b-pagination
          size="is-small"
          :simple="false"
          :total="ContactGroups.TotalRows"
          :current.sync="search.currentPaginationPage"
          :per-page="ContactGroups.Limit"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          v-on:change="paginatePage"
        ></b-pagination>
      </div>
      <div class="column has-text-right">
        <button class="button is-primary is-medium" v-on:click="addContactGroup">Add Contact Group</button>
      </div>
    </div>

    <contact-group-insert-or-update
      v-if="EditContactGroup"
      :contact-group="EditContactGroup"
      v-on:close="EditContactGroup = null"
      v-on:add="addContactGroupSuccess"
    ></contact-group-insert-or-update>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  components: {
    contactGroupInsertOrUpdate: () =>
      import("../components/organisms/contactGroupInsertOrUpdate.vue")
  },
  data: function() {
    return {
      APIUrl: process.env.VUE_APP_APIURL,
      ContactGroups: null,
      EditContactGroup: null,
      isLoading: false,
      search: {
        currentPaginationPage: 1,
        skip: null,
        size: 10,
        nameContains: null,
        groupType: null
      }
    };
  },
  created() {
    this.search.size = this.$route.query.size || 10;
    this.search.skip = this.$route.query.skip ? this.$route.query.skip : 0;
    this.setDataFromQuery();
    this.updateUrlAndLoadPage(true);
  },
  methods: {
    setDataFromQuery() {
      var self = this;
      this.listOfSearchParameters.forEach(function(s) {
        if (self.$route.query[s]) self.search[s] = self.$route.query[s];
      });
    },
    addContactGroup() {
      this.EditContactGroup = {
        Id: null,
        Name: "",
        GroupType: 0,
        Contacts: []
      };
    },
    updateFilter() {
      this.search.skip = 0;
      this.search.currentPaginationPage = 1;
      this.updateUrlAndLoadPage();
    },
    updateUrlAndLoadPage(replace) {
      var url = "contact-group" + this.queryStringSearch;

      if (replace) this.$router.replace(url);
      else this.$router.push(url);

      this.loadData();
    },
    loadData() {
      this.isLoading = true;
      this.axios.get(this.apiEndpointUrl).then(response => {
        this.ContactGroups = response.data;
        this.isLoading = false;
      });
    },
    paginatePage(page) {
      this.search.currentPaginationPage = page;
      if (this.search.currentPaginationPage <= 0)
        this.search.currentPaginationPage = 1;
      this.search.skip =
        (this.search.currentPaginationPage - 1) * parseInt(this.search.size);
      this.updateUrlAndLoadPage();
    },
    addContactGroupSuccess(contactGroup) {
      this.EditContactGroup = null;
      this.loadData();
    },

    confirmDeleteContactGroup(p) {
      if (p.LinkedProducts > 0) {
        this.$toast.open(
          "Cannot delete contact group " +
            p.Name +
            " as it is still linked with " +
            p.LinkedProducts +
            " products."
        );
      } else if (p.LinkedDestinations) {
        this.$toast.open(
          "Cannot delete contact group " +
            p.Name +
            " as it is still linked with  " +
            p.LinkedDestinations +
            " destinations. "
        );
      } else {
        var msg =
          "Are you sure you want to <b>DELETE</b> contact Group <b>" +
          p.Name +
          "</b>?";

        msg +=
          "<br><br>It Contains <b>" +
          p.Contacts.length +
          "</b> contact records";

        this.$dialog.confirm({
          title: "Delete " + p.Name,
          message: msg,
          type: "is-danger",
          confirmText: "Delete",
          onConfirm: () => this.deleteContactGroup(p)
        });
      }
    },
    deleteContactGroup(p) {
      var url = this.APIUrl + "/contact-group/" + p.Id;
      this.axios
        .delete(url)
        .then(response => {
          this.$toast.open(
            "Successfully deleted contact group <b>'" + p.Name + "'</b>"
          );

          this.loadData();
        })
        .catch(this.catchError);
    }
  },
  computed: mapState({
    // arrow functions can make the code very succinct!

    apiEndpointUrl: function() {
      var url = this.APIUrl + "/contact-group" + this.queryStringSearch;
      return url;
    },
    queryStringSearch: function() {
      var url = "?take=" + this.search.size + "&skip=" + this.search.skip;

      var self = this;
      this.listOfSearchParameters.forEach(function(s) {
        if (self.search[s] !== null) url += "&" + s + "=" + self.search[s];
      });

      return url;
    },
    listOfSearchParameters() {
      var arr = ["nameContains", "groupType"];
      return arr;
    }
  })
};
</script>
